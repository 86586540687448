/**  =====================
      Authentication css start
==========================  **/
.auth-user-wrapper {
  position: relative;

  .sign-up-ci {
    height: 44px;
    width: 128px;
  }

  .submit__box {
    margin-bottom: 12px;
    margin-top: 20px;
    text-align: center;
    .submit__btn {
      width: 300px;
      font-size: 16px;
      font-weight: 700;
      text-transform: initial;
    }
    .small_submit__btn {
      width: 200px;
      font-size: 16px;
      font-weight: 700;
      text-transform: initial;
    }
    .login_submit__btn {
      width: 80px;
      font-size: 14px;
      font-weight: 500;
      text-transform: initial;
    }
  }
}
.auth-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
  a,
  p > a {
    color: $theme-heading-color;
    font-weight: 600;
  }
  .btn-auth-gen {
    .btn-icon {
      width: 140px;
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 45px;

      small {
        font-size: 15px;
      }
    }
  }
  .input-group {
    background: transparent;
  }
  .card {
    margin-bottom: 0;
  }

  > div {
    z-index: 5;
  }
  .input__box {
    display: block;
    &.flex-align-items-center {
      display: flex;
      align-items: center;
    }
  }
  .login_input__box {
    height: 47px;
    display: block;
    &.flex-align-items-center {
      display: flex;
      align-items: center;
    }
  }
  .input__icon_success {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    transform: rotate(-45deg);
    margin-top: -6px;
    display: block;
    width: 13px;
    height: 8px;
    border: 2px solid transparent;
    border-left-color: rgb(29, 167, 224);
    border-bottom-color: rgb(29, 167, 224);
  }
  .input__label {
    display: inline-block;
    margin-top: 5px;
    line-height: 1.5;
    font-size: 14px;
    color: #555555;
    /* text-transform: uppercase; */
    .input__label_icon.necessary {
      font-family: sans-serif;
      font-weight: 700;
      color: #d40000;
    }
  }

  .auth-content {
    position: relative;
    // width: 390px;
    // padding: 15px;
    z-index: 5;
    &.multyform,
    &.subscribe {
      width: 750px;
    }
  }

  .auth-home {
    position: relative;
    width: 800px;
    padding: 15px;
    z-index: 5;
    &.multyform,
    &.subscribe {
      width: 750px;
    }
  }

  .auth-signUp-content {
    position: relative;
    width: 500px;
    padding: 15px;
    z-index: 5;
    &.multyform,
    &.subscribe {
      width: 750px;
    }
  }

  .auth-password-reset-content {
    position: relative;
    width: 500px;
    padding: 15px;
    z-index: 5;
    &.multyform,
    &.subscribe {
      width: 750px;
    }
  }

  .auth-mypage-content {
    position: relative;
    width: 500px;
    padding: 15px;
    z-index: 5;
    &.multyform,
    &.subscribe {
      width: 750px;
    }
  }

  .verify__input_timer {
    position: absolute;
    top: 50%;
    right: 15px;
    color: #777777;
    transform: translate(0, -50%);
    color: #555555;
  }

  .submit__box {
    margin-bottom: 12px;
    margin-top: 30px;
    text-align: center;
    .submit__btn {
      width: 300px;
      font-size: 16px;
      font-weight: 700;
      text-transform: initial;
    }
    .small_submit__btn {
      width: 150px;
      font-size: 16px;
      font-weight: 700;
      text-transform: initial;
    }
    .login_submit__btn {
      width: 80px;
      font-size: 14px;
      font-weight: 500;
      text-transform: initial;
    }
  }

  .menu__box {
    margin-bottom: 10px;
    margin-top: 0px;
    text-align: center;
    .menu__btn {
      width: 300px;
      font-size: 16px;
      font-weight: 500;
      text-transform: initial;
    }
  }

  @media only screen and (max-width: 768px) {
    max-width: 360px;
  }
  @media only screen and (max-width: 575px) {
    .card {
      .card-body {
        padding: 30px 15px;
      }
    }
  }

  .auth-icon {
    font-size: 30px;
    &:before {
      background: $theme-color;
      background-clip: inherit;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .sign-up-ci {
    height: 44px;
    width: 128px;
  }

  &.offline {
    background-image: none;

    &:before {
      display: none;
    }
  }

  .auth-bg {
    .r {
      position: absolute;
      width: 300px;
      height: 300px;
      border-radius: 50%;

      &:first-child {
        top: -100px;
        right: -100px;
        background: $theme-color;
      }

      &:last-child {
        left: -100px;
        bottom: -100px;
        background: $theme-color2;
      }

      &.s {
        width: 20px;
        height: 20px;
        &:nth-child(2) {
          top: 150px;
          right: -150px;
          background: $primary-color;
        }

        &:nth-child(3) {
          left: -150px;
          bottom: 150px;
          background: $success-color;
        }
      }

      &:nth-child(odd) {
        animation: floating 7s infinite;
      }

      &:nth-child(even) {
        animation: floating 9s infinite;
      }
    }
  }
}

.auth-accept-font {
  font-size: 13px;
}

.mypage-use-service-table {
  background-color: #f4f4f4;
}

/* image variant start */
.aut-bg-img {
  background-size: cover;
  /*background-image: url('../../../images/bg-images/bg4.jpg');*/ // amit images
  background-repeat: no-repeat;
  background-position: center;
}
.aut-bg-img-side {
  p {
    line-height: 2;
  }
}
.aut-bg-img,
.aut-bg-img-side {
  .custom-select,
  .form-control {
    background: transparent;
  }
}
.auth-tabs {
  .card {
    // border-right: 10px;
  }
  .nav-pills {
    // margin-top: -80px;
  }
  .tab-content {
    overflow: hidden;
    position: relative;
    .tab-pane > * {
      position: relative;
      z-index: 5;
    }
    .auth-icon {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 180px;
      transform: rotate(-30deg);
      z-index: 1;
      opacity: 0.2;
    }
  }
}
/* image varient start */
@keyframes floating {
  from {
    transform: rotate(0deg) translate(-10px) rotate(0deg);
  }

  to {
    transform: rotate(360deg) translate(-10px) rotate(-360deg);
  }
}
/**====== Authentication css end ======**/
