.scrollable{
    height: 400px;
    overflow-y: scroll;
}
.scrollable-x{
    overflow-x: scroll;
}
.timeline{
    position: relative;
    top:-40px;
   
    height:40px;
    border-radius: 30px;
    background:linear-gradient(90deg, rgb(0, 166, 226) 0%, rgb(91, 134, 229) 100%);
    // background:linear-gradient(90deg,
    //  rgb(255,196,0) 25%,
    //  rgb(15,220,15) 35%,
    //  rgb(5, 171, 233) 65%,
    //  rgb(0, 0, 0) 95%,
    //  rgb(154, 0, 229) 100%);
    background-color:orange /*this your primary color*/
}
.stage-0{
    width:25%;
}
.stage-1{
    width:50%;
}
.stage-2{
    width:75%;
}
.stage-3{
    width:100%;
}
.sync-stage-0{
    width:25%;
}
.sync-stage-1{
    width:50%;
}
.sync-stage-2{
    width:75%;
}
.sync-stage-3{
    width:100%;
}
.bridge-col{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.bridge-stage-0{
    width:20%;
}
.bridge-stage-1{
    width:40%;
}
.bridge-stage-2{
    width:60%;
}
.bridge-stage-3{
    width:75%;
}
.bridge-stage-4{
    width:100%;
}
.timeline-text{
    position: relative;
    top:-82px;
}
.timline-background {
    position: relative;
    width:100%;
    height:40px;
    border-radius: 30px;
    background:rgb(187, 187, 187);
}
.timeline-btn{
    border-color: transparent;
}

.timeline-btn:hover{
    border-color: transparent;
    background:transparent;
}
.create {
    background: #D20000
}
.upload {
    background : #FEB700
}
.download {
    background : #00884F
}
.completed {
    background : #75259A
}
.round{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    font-size: 20px;
    color: white;
    vertical-align: middle;
    padding-left: 4px;
    margin: 0px;
    margin-right:10px;
    line-height: 19px;
    float: left;;
}
.switch-on, .switch-off{
    line-height: calc(12px) !important;
}

.toggle-label{
    height:30px;
}
.delete-btn{
    float:right;
    width:25px;
    height:25px;
    border-radius: 30px;
    background:lightslategray;
    color:white;
    text-align: center;
    vertical-align: middle;
    // margin-left:10px;
    cursor:pointer;
}
.currency-card{
    border-radius : 20px;
    // border: 1px solid rgb(0, 166, 226);
    box-shadow: 2px 1px 1px rgb(0, 166, 226);
    height:35px;
    width:100%;
}

.currency-card-item{
    width:80%;
    margin-left:10%;
    padding-left:10px;
    font-size: 1rem;
    vertical-align: middle;
    height: 30px;
    padding-top:3px;
    // display: table-cell;
}
.cursor-btn{
    cursor:pointer;
}

.quill-div{
    width:100%;
    border:1px solid lightgray;
    height:250px;
}

.quill-div-transparent{
    width:100%;
    border:1px solid transparent;
    height:auto;
}
div.ql-toolbar{
    display:none;
}

.align-right{
    float:right;
}

.ql-container.ql-snow {
    border-color: transparent !important;
}

.form-control{
    height: calc(2.0em + 1rem + 2px)
}

.history-card{
    border-radius: 2px;
    border : 1px black;
}

.dashboard-card-header{
    height : 80px;
}

.dashboard-card-footer{
    height : 80px;
}

.auth-card-footer {
    border-top: 3px solid #f1f1f1;
    background: transparent;
    padding: 10px;
}

.dashboard-list-body{
    height : 330px;
}

.dashboard-chart-body{
    height : 410px;
}

.dashboard-date-picker{
    width:205px !important;
}

.dashboard-button{
    width: 95px;
}

.admin-detail-label{
    width:40px;
}
.admin-detail-status-box {
    display: flex;
    // justify-content: space-between; // 좌우 정렬방식
    // justify-content: flex-start; // 좌우 정렬방식
    // align-items: center; // 수직 정렬 방식
}
.admin-detail-temp-pw-reset{
    display: flex;
}